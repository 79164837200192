var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"CustomerList"},_vm._l((_vm.list),function(item){return _c('div',{key:item.id,staticClass:"item acea-row row-between-wrapper",on:{"click":function($event){_vm.$router.push(
        '/customer/chat/' +
          item.uid +
          '/' +
          _vm.productId +
          (_vm.orderId ? '?orderId=' + _vm.orderId : '')
      )}}},[_c('div',{staticClass:"pictrue"},[_c('img',{attrs:{"src":item.avatar}})]),_c('div',{staticClass:"text line1"},[_vm._v(_vm._s(item.nickname))])])}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }